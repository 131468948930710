import React from 'react'
import CookieConsent from 'react-cookie-consent'


const CookieBar = () => (
  <CookieConsent
      location="bottom"
      buttonText="Prijať"
      cookieName="analytics"
  >
    Pre zlepšenie kvality našich stránok používame cookies. Ich používanie môžete
    odmietnuť nastavením svojho internetového prehliadača.
  </CookieConsent>
)

export default CookieBar
