import React from 'react'
import styled from '@material-ui/core/styles/styled'
import useTheme from '@material-ui/core/styles/useTheme'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import logoCircle from '../images/logo-circle.svg'
import bg from '../images/logo.svg'


const StyledFooter = styled('footer')({
  backgroundColor: '#262E2C',
  padding: '1.5rem 1rem',
  width: '100%',
  color: 'white',
  textAlign: 'center',
})

const StyledAbout = styled('div')(({theme}) => ({
  display: 'flex',
  maxWidth: '1200px',
  margin: '10rem auto 0',
  padding: '1.5rem 1rem',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    marginTop: '1rem',
  },
}))

const StyledParagraph = styled('p')(({theme}) => ({
  flex: 2,
  borderTop: '2px solid #262E2C',
  margin: '0 1rem',
  padding: '1.5rem 1rem',
  textAlign: 'justify',
  [theme.breakpoints.down('sm')]: {
    order: 2,
  },
}))

const StyledLogoWrapper = styled('div')(({theme}) => ({
  flex: 1,
  transform: 'translateY(-50%)',
  [theme.breakpoints.down('sm')]: {
    transform: 'none',
    margin: '0 1rem 1rem',
    order: 1,
  },
  [theme.breakpoints.up('sm')]: {
    maxWidth: '12rem',
  },
}))

const StyledLink = styled('a')({
  display: 'inline-block',
  color: 'white',
  textDecoration: 'underline',
  cursor: 'pointer',
})

const StyledExclamation = styled('span')({
  display: 'block',
  fontWeight: 'bold',
  textAlign: 'center',
})

const Footer = () => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <>
      <StyledAbout>
        <StyledParagraph>
          Tento projekt sme vytvorili ako podporu pre stravovacie zariadenia v čase pandémie Covid-19.
          Touto cestou Vám chceme umožniť jednoducho a na jednom mieste nájsť nové podniky alebo znovu objaviť tie
          staré, ktoré sa nachádzajú v našom meste. Budeme veľmi radi, ak o tomto projekte dáte vedieť aj svojim
          priateľom, rodine, či známym v okolí. Zostaňte zdraví!
        </StyledParagraph>
        <StyledLogoWrapper>
          <img src={matches ? bg : logoCircle} alt="Logo hladný ako vlk" />
        </StyledLogoWrapper>
        <StyledParagraph>
          Sme v tom spoločne! Dodržujte, prosím, epidemiologické opatrenia vydané Ústredným krízovým štábom.
          <br />
          <br />
          <StyledExclamation>Za správnosť údajov nezodpovedáme</StyledExclamation>
        </StyledParagraph>
      </StyledAbout>
      <StyledFooter>
        All rights reserved ©&nbsp;
        {new Date().getFullYear()}&nbsp;
        <StyledLink href="https://sited.sk" target="_blank" rel="noopener noreferrer">Sited, s.r.o.</StyledLink>
      </StyledFooter>
    </>
  )
}

export default Footer
