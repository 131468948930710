import React from 'react'
import PropTypes from 'prop-types'
import styled from '@material-ui/core/styles/styled'
import Typography from '@material-ui/core/Typography'
import CallIcon from '@material-ui/icons/Call'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import {trackCustomEvent} from 'gatsby-plugin-google-analytics'
import hands from '../images/ror-hands.svg'
import mask from '../images/ror-mask.svg'
import distance from '../images/ror-distance.svg'
import recyclation from '../images/recyclation.svg'


const StyledLink = styled('a')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: '1px solid #272e2c',
  borderRadius: '50%',
  width: '1rem',
  height: '1rem',
  color: '#272e2c',
  fontSize: '1.5rem',
  padding: '1rem',
  marginLeft: '0.5rem',
  marginBottom: '0.5rem',
})

const StyledImagesWrapper = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  [theme.breakpoints.down('sm')]: {
    flexWrap: 'wrap',
  },
}))

const StyledWarnings = styled('div')(({theme}) => ({
  'width': '100%',
  'padding': '1rem',
  'height': '100%',
  'textAlign': 'center',
  '&:not(:last-child)': {
    borderRight: '1px solid #262E2C',
  },
  [theme.breakpoints.down('sm')]: {
    '&:not(:last-child)': {
      borderRight: 0,
      borderBottom: '1px solid #262E2C',
    },
  },
}))

const StyledWarning = styled('div')(({theme}) => ({
  'display': 'flex',
  'alignItems': 'center',
  'textAlign': 'left',
  '&:not(:last-child)': {
    marginBottom: '1rem',
  },
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'flex-start',
    width: '15rem',
    margin: '0 auto',
  },
}))

const StyledIcon = styled('img')(({theme}) => ({
  width: '3.5rem',
  marginRight: '1.5rem',
}))

const StyledRecyclationIcon = styled('img')({
  width: '70%',
  marginBottom: '0.5rem',
})


const CallModal = ({title, mobileNumber, ...props}) => {
  const [isOpen, setOpen] = React.useState(false)

  const handleToggle = () => {
    setOpen((prevState) => !prevState)
  }

  const handleOpen = () => {
    trackCustomEvent({category: `Call button – ${title}`, action: 'Call', label: `${title} – ${mobileNumber}`})
    setTimeout(() => {
      setOpen((prevState) => !prevState)
    }, 3000)
  }

  return (
    <>
      <StyledLink href={`tel:${mobileNumber}`} onClick={handleOpen} {...props}>
        <Typography variant="srOnly">Zavolať</Typography>
        <CallIcon />
      </StyledLink>
      <Dialog
          open={isOpen}
          onClose={handleToggle}
          maxWidth="md"
          aria-labelledby="Ďakujeme za objednávku"
          aria-describedby="Ďakujeme za objednávku"
      >
        <DialogTitle>Ďakujeme, že ste podporili prevádzku {title}</DialogTitle>
        <DialogContent>
          <StyledImagesWrapper>
            <StyledWarnings>
              <StyledRecyclationIcon src={recyclation} alt="Recyklácia" />
              <Typography>
                Polystyrénové a plastové obaly opláchnite vodou od zbytkov jedla a vyhoďte do žltého kontainera
              </Typography>
            </StyledWarnings>
            <StyledWarnings>
              <StyledWarning>
                <StyledIcon src={mask} alt="Vlk s rúškom" />
                <Typography>
                  Noste rúško
                </Typography>
              </StyledWarning>
              <StyledWarning>
                <StyledIcon src={distance} alt="Čistenie rúk" />
                <Typography>
                  Dodržujte odstup
                </Typography>
              </StyledWarning>
              <StyledWarning>
                <StyledIcon src={hands} alt="Čistenie rúk" />
                <Typography>
                  Umývajte si ruky
                </Typography>
              </StyledWarning>
            </StyledWarnings>
          </StyledImagesWrapper>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToggle} variant="outlined" autoFocus>
            Zavrieť
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

CallModal.propTypes = {
  title: PropTypes.string.isRequired,
  mobileNumber: PropTypes.string.isRequired,
}

export default CallModal
