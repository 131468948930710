import React from 'react'
import PropTypes from 'prop-types'
import {Choose, When, Otherwise} from 'babel-plugin-jsx-control-statements'
import styled from '@material-ui/core/styles/styled'
import {ModalRoutingContext} from 'gatsby-plugin-modal-routing'
import banner from '../images/logo.svg'
import Header from './Header'
import Footer from './Footer'
import CookieBar from './CookieBar'


const StyledMain = styled('main')({
  maxWidth: '1200px',
  margin: '1rem auto',
  padding: '1.5rem 1rem',
})


const Layout = ({isModal, children}) => (
  <ModalRoutingContext.Consumer>
    {({modal, closeTo}) => (
      <Choose>
        <When condition={modal || isModal}>
          {children}
        </When>
        <Otherwise>
          <Header banner={banner} />
          <StyledMain>
            {children}
          </StyledMain>
          <Footer />
          <CookieBar />
        </Otherwise>
      </Choose>
    )}
  </ModalRoutingContext.Consumer>
)


Layout.propTypes = {
  isModal: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

export default Layout
