import React from 'react'
import PropTypes from 'prop-types'
import styled from '@material-ui/core/styles/styled'
import useTheme from '@material-ui/core/styles/useTheme'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import logoCircle from '../images/logo-circle.svg'


const StyledHeader = styled('header')(({theme}) => ({
  width: '60%',
  margin: '3rem auto',
  padding: '0 2rem',
  [theme.breakpoints.down('sm')]: {
    width: '60%',
  },
  [theme.breakpoints.down('xs')]: {
    width: '70%',
  },
}))

const Header = ({banner, ...props}) => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <StyledHeader {...props}>
      <img src={matches ? logoCircle : banner} alt="Banner" />
    </StyledHeader>
  )
}

Header.propTypes = {
  banner: PropTypes.string.isRequired,
}

export default Header
